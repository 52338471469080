<template lang="">

<div>
    <!-------------------------------- Bloc Personnes rencontrées -------------------------------->


 <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="6"
            class="pa-0 font_color text-left"
          >
            {{title?title:"SELECTIONNER UN ARTICLE"}}
          </v-col>

    <v-col cols="6" lg="6" xl="4" offset-xl="2"  class="pa-0 text-right">
      <v-btn v-if="mode !== 'visualisation'" :disabled="disabled" class="btnBold"  color="#FF9900" dark rounded @click="dialog=true" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              Ajouter un article
            </div>
      </v-btn>
     </v-col>
    </v-toolbar>

        <Popup :dialog="dialog" text="" actions="" heightProp="" widthProp="70vW"  @emitDialog="dialog = $event" showClose="true">
                <!-- Formulaire personnes -->
    <v-col cols="12" sm="12" class="pb-0 pl-8 pr-16 ">
      <form  @submit.prevent="submit">


        
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            AJOUTER UN ARTICLE
          </v-col>

        </v-toolbar>
  


            <v-col cols="12" sm="12" class="pt-5">
              <div class="text-left">DESIGNATION DU PRODUIT OU CODE NAVISION*

              </div>

                <v-autocomplete
                :items="articles_retour"
                item-value="id_article_retour"
                item-text="article"
                return-object
                v-model="article_retour"
                outlined
                dense
                color="#CFCFCF"
                :search-input.sync="searchInput"
                no-data-text="Aucun article trouvé"
                hide-details
                cache-items
              >
              </v-autocomplete>

          <!-- message d'erreur -->
                      <div class="errorMsg" v-if="$v.article_retour.id_article_retour.$error && $v.article_retour.id_article_retour.required == false">
            Renseignez l'article.
          </div>
            <!-- message d'erreur -->

            </v-col>

            <v-col  v-if="blocFore" cols="12" sm="12" class="pt-5">
              <div class="text-left">DESIGNATION ET CODE FOURNISSEUR DU PRODUIT NON CODIFIE</div>

                <v-text-field
                v-model="article_retour.designation_non_codifie"
                outlined
                dense
                color="#CFCFCF"
                hide-details
              >
              </v-text-field>
                <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.article_retour.designation_non_codifie.$error && $v.article_retour.designation_non_codifie.required == false">
                Renseignez l'article.
              </div>
                <!-- message d'erreur -->
            </v-col>



            <v-col  v-if="blocFore" cols="12" sm="12" class="pt-5">
              <div class="text-left">REPERE*</div>

                <v-text-field
                v-model="article_retour.article_repere"
                outlined
                dense
                color="#CFCFCF"
                hide-details
              >
              </v-text-field>
                <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.article_retour.article_repere.$error ">
                Renseignez le repère.
              </div>
                <!-- message d'erreur -->
            </v-col>

            <v-col cols="12" sm="6" class="pt-5">
              <div class="text-left">
                  QUANTITE*

             </div>
              <v-text-field label="" single-line outlined dense v-model="article_retour.article_quantite" hide-details   ></v-text-field>
                                          <div class="errorMsg" v-if="$v.article_retour.article_quantite.$error && $v.article_retour.article_quantite.required == false">
                    Renseigner la quantité.
                </div>

                <div class="errorMsg" v-if="$v.article_retour.article_quantite.$error && $v.article_retour.article_quantite.numeric == false">
                    Renseigner seulement une valeur numérique.
                </div>
            </v-col>

            <v-col v-if="article_retour.designation_non_codifie" cols="12" sm="6" class="pt-5">
                <div class="text-left">
                      PRIX UNITAIRE (NON CODIFIE)*

                </div>
                <v-text-field label="" single-line outlined dense v-model="article_retour.article_puht" hide-details   ></v-text-field>
                <div class="errorMsg" v-if="$v.article_retour.article_quantite.$error && $v.article_retour.article_quantite.required == false">
                    Renseigner le prix unitaire.
                </div>

                <div class="errorMsg" v-if="$v.article_retour.article_quantite.$error && $v.article_retour.article_quantite.numeric == false">
                    Renseigner seulement une valeur numérique.
                </div>
            </v-col>







                  <v-col cols="12" sm="12"  class="">
              <v-btn :disabled="disabled" class="btnBold"  color="#FF9900" dark rounded type="submit" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              Ajouter cet article
            </div>
      </v-btn>
      </v-col>

    </form>

        </v-col>
      </Popup>

        <Popup :dialog="dialogDelete" text="Supprimer cet article ?" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false"> 
                     <v-card-actions class="mb-10">
              
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="closeDelete">Non</v-btn>
              <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
              
            </v-card-actions> 
        </Popup>
   <div v-if="retour_a_articles.length == 0 && mode == 'visualisation'" class="text-left font-weight-bold"> Aucun article renseigné. </div>
  <div class="text-left pt-4 pb-5" v-if="retour_a_articles.length == 0">
           Merci d'ajouter au moins un article
  </div>
      <v-col cols="12" sm="12" class="pb-0 pl-0 ">
        <v-data-table  v-if="retour_a_articles.length > 0"
          :headers="headers"
          :items="retour_a_articles"
          :items-per-page="10"
          class="elevation-1 mt-10"
        >
      <template v-if="mode !== 'visualisation'" v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  
  
  </v-data-table>


  </v-col>

<v-col v-if="retour_a_articles.length > 0 && retour_a_articles[0].article_total" cols="12" sm="12" class="pb-0 pl-0 text-right font-weight-bold text-h5">
    Total: {{ (retour_a_articles.reduce((partialSum, a) => (a.article_total) ? partialSum + a.article_total : partialSum, 0)).toLocaleString() }}€
</v-col>



<!--       
      <template>
    <div class="card">

        <slot name="test">blabla</slot>
    </div>
    </template> -->



      <!-------------------------------- BLOC PERSONNES RENCONTREES -------------------------------->
    </div>

</template>
<script>
import {
  required,
  numeric,
  requiredIf
} from "vuelidate/lib/validators";
 import Popup from "@/components/shared/Popup";
 import apiArticlesRetour from "@/api/articles_retour";
export default {
  props: {
    retour_a_articles: {
      type: Array,
      default: () => []
    },
    mode: [String],
    title: [String],
    blocFore: {
        type: Boolean,
        default: false
    }
  },
  components: {
    Popup
  },
  data() {
    return {
        message: "Supprimer cet article ?",
        headers: [
        { text: 'Article', align: 'start', value: 'article', sortable: false },
        { text: 'quantite', value: 'article_quantite', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      dialogDelete: false,
      disabled: false,
      dialog: false,
      height: "",
      width: "",
      articles_retour: [],
      article_retour: {
        id_article_retour: null,
        designation_non_codifie: "",
        article_quantite: null,
        article: null,
        article_repere: "",
      },
      nbResult : process.env.VUE_APP_NB_DISPLAY,
      searchInput: ""
    };
  },
  validations: {
    article_retour: {
      id_article_retour: {
        required: requiredIf(function () {
          return this.article_retour.designation_non_codifie == '';
        }),
      },
      designation_non_codifie: {
        required: requiredIf(function () {
          return this.article_retour.id_article_retour == null;
        }),
      },
      article_quantite: {
        required,
        numeric,
      },
      article_repere: {
        required: requiredIf(function () {
          return this.blocFore;
        }),      }
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dialog = false;
        console.log(this.article_retour);
        const prix = this.article_retour.article_puht ? this.article_retour.article_puht : this.article_retour.prix;
        this.retour_a_articles.push({
          id_article_retour: this.article_retour.id_article_retour,
          article: this.article_retour.article,
          article_label: this.article_retour.article,
          article_quantite: this.article_retour.article_quantite,
          article_repere: this.article_retour.article_repere,
          article_puht: prix,
          article_puht_string: prix ? `${Math.round(prix * 100) / 100}€` : '',
          article_total: prix * this.article_retour.article_quantite,
          article_total_string: prix ? `${Math.round(prix * this.article_retour.article_quantite) / 100}€` : '',
          designation_non_codifie: this.article_retour.designation_non_codifie
        });
        console.log(this.retour_a_articles, 'articless');

        this.article_retour = {
          id_article_retour: null,
          article_quantite: null
        }
        this.searchArticle("" ,this.nbResult);
        this.$v.$reset();
      }


    },
    searchArticle(query,nbResult){
      apiArticlesRetour.getArticlesRetour(nbResult,query).then((res) => {
      this.articles_retour = res.data.articles_retour
      // for ( var i = 0; i < this.articles_retour.length; i++) {
      //   this.articles_retour[i].article_ref = this.articles_retour[i].article + ' - ' + this.articles_retour[i].reference
      // }

      this.articles_retour.forEach((element) => {
        element["article"] = element["article"] + ' - ' + element["reference"];
      })
      })

    },
    deleteItem (item) {
      this.editedIndex = this.retour_a_articles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.retour_a_articles.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
    watch:{
      searchInput(newValue){

        this.searchArticle(newValue,this.nbResult)
        
      },
    },
  mounted() {
    this.searchArticle("" ,this.nbResult);
    if (this.blocFore) {
      this.headers = [
        { text: 'Article', align: 'start', value: 'article_label', sortable: false },
        { text: 'Article non codifié', align: 'start', value: 'designation_non_codifie', sortable: false },
        { text: 'Repère', value: 'article_repere', sortable: false },
        { text: 'Quantité', value: 'article_quantite', sortable: false },
        { text: 'Prix d\'achat', value: 'article_puht_string', sortable: false },
        { text: 'Total', value: 'article_total_string', sortable: false },
         { text: 'Actions', value: 'actions', sortable: false },
      ];
    } else {
      if (this.mode == "visualisation") {
        this.headers = [
          { text: 'Article', align: 'start', value: 'article', sortable: false },
          { text: 'quantite', value: 'article_quantite', sortable: false },
        ];
      }
    }

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

.actions{
    padding: 2% 5%;
    font-weight: bold;
    font-size: 1.2em;
}

::v-deep .menu-chips.theme--dark.v-list,
.v-chip.v-chip--clickable.v-chip--no-color.v-chip--pill.v-chip--removable.theme--light.v-size--default,
::v-deep .v-icon.notranslate.v-chip__close.v-icon--link.v-icon--right.mdi.mdi-delete.theme--light {
  background: $background-color-blue;
  color: #fff;
}
</style>
