<template>
  <v-row>
    <v-col cols="12">
      <Title :titlePage="titlePage" />
    </v-col>

    <v-container>
      <RapportForm :btnDisable="btnDisable" :utilisateurs="utilisateurs" :rapport="rapport" :mode="mode" @rapport="createOrUpdateRapport" @closeRappel="deleteRappel" />
      <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />
    </v-container>
  </v-row>
</template>
<script>
import Title from "@/components/shared/Title";
import RapportForm from "@/components/Clients/RapportForm";
import Popup from "@/components/shared/Popup";
import apiDoc from "@/api/document";
import api from "@/api/rapport";
import api_rappels from "@/api/rappel";
import api_user from "@/api/user";
import api_entreprise from "@/api/entreprise";


export default {
  components: {
    Title,
    RapportForm,
    Popup,
  },
  data() {
    return {
      titlePage: "NOUVEAU RAPPORT",
      rapport: {
        id_rapport: null,
        date_rapport: "",
        rapport_appel: false,
        rapport_visite: false,
        description: "",
        // rapport_obtenu: "",
        commentaire_rapport: "",
        // personnes: [],
        Personnes: [],
        rappels: [],
        documents: [],
        action_a_realiser: null,
        commentaire_action: "",
        type_rapport: "",
        infos_revendeur: {
          familles_produits: [
    {
        "id": 1,
        "libelle": "Distribution",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 2,
        "libelle": "Valves",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 3,
        "libelle": "Commandes à distance",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 4,
        "libelle": "Cartouches - Blocs forés",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 5,
        "libelle": "Mini-centrale / Groupe MP",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 6,
        "libelle": "Filtrations",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    },
    {
        "id": 7,
        "libelle": "Pompes - Moteurs - Diviseurs",
        "pasDePotentiel": false,
        "aConsolider": false,
        "aDevelopper": false,
        "actionsPourDevelopper": ""
    }
]
        },
        id_utilisateur: this.$store.state.user.id_utilisateur
      },
      utilisateurs: [],
      dialog: false,
      btnDisable:false,
      messageCreation: "",
      actions: {
        route: [
          {
            btn: "OK",
            routeName: "client",
            params: { id_entreprise: this.$route.params.id_entreprise },
          },
        ],
      },
      mode: "creation",
    };
  },

  methods: {
    createOrUpdateRapport(rapport) {
      console.log("🚀 ~ createOrUpdateRapport ~ rapport:", rapport)
      rapport.id_entreprise = this.$route.params.id_entreprise;
      // this.rapport = rapport;
      if (this.mode == "creation") {
        this.messageCreation = "Un nouveau rapport a été créé";

        api.createRapport(rapport.rapport).then((res) => {
          console.log("on est dans la reponse", res.data);
          var id_rapport = res.data.rapport.id_rapport;

          let data = new FormData();
          data.append("mode", "rapports");
          data.append("directory", id_rapport);
          data.append("id_element", id_rapport);
          for (var j = 0; j < rapport.documents.length; j++) {
            // if (this.docs[j].id_document === undefined) {
            let file = rapport.documents[j].files;
            data.append("documents", file);
          }

          apiDoc
            .uploadDocument(rapport.id_entreprise, data, rapport.config)
            .then((res) => {
              console.log("res upload", res);
              this.dialog = true;
              this.btnDisable = false;
            });
            
        });
      } else {
        console.log(
          "rapport",
          rapport,
          "rapport.rapport",
          rapport.rapport,
          this.rapport
        );
        this.messageCreation = "Le rapport a été modifié";
        api.updateRapport(rapport.rapport).then((res) => {
          var id_rapport = res.data.id_rapport;
          console.log(rapport.documents.entries().next().done)
         

             let data = new FormData();
          data.append("mode", "rapports");
          data.append("directory", id_rapport);
          data.append("id_element", id_rapport);
          for (var j = 0; j < rapport.documents.length; j++) {
            // if (this.docs[j].id_document === undefined) {
            let file = rapport.documents[j].files;
            data.append("documents", file);
          }



            console.log("doc to send")
            apiDoc
              .uploadDocument(rapport.id_entreprise, data, rapport.config)
              .then((res) => {
                console.log("res upload", res);
                
              });
          
          this.dialog = true;
          console.log(
            "on est dans la reponse",
            res.data,
            id_rapport,
            res.data.id_rapport
          );
        });
      }
    },
    getRapport(id_rapport) {
      api.getRapport(id_rapport).then((res) => {
        // console.log("🚀 ~ api.getRapport ~ res:", res.data.rapport[0].RapportInfosRevendeur)
        res.data.rapport[0].infos_revendeur = res.data.rapport[0].RapportInfosRevendeur;
        this.rapport = res.data.rapport[0];
        console.log("🚀 ~ api.getRapport ~ this.rapport:", this.rapport)
        // this.rapport.id_rapport = res.data.rapport[0].id_rapport;
        // this.rapport.date_rapport = res.data.rapport[0].date_rapport;
        // this.rapport.description = res.data.rapport[0].description;
        // this.rapport.action_a_realiser = res.data.rapport[0].action_a_realiser;
        // this.rapport.commentaire_action = res.data.rapport[0].commentaire_action;


        if(this.rapport.infos_revendeur){
        this.rapport.infos_revendeur = res.data.rapport[0].RapportInfosRevendeur
        this.rapport.infos_revendeur.familles_produits = JSON.parse(this.rapport.infos_revendeur.familles_produits)
        }


        this.rapport.type_visite = this.rapport.appel == 1 ? "APPEL" : "VISITE";

        this.rapport.action_a_realiser = this.rapport.action_a_realiser ? this.rapport.action_a_realiser.toString() : false;
        // this.rapport.personnes = res.data.rapport[0].Personnes;
        this.rapport.documents = res.data.rapport[0].Documents;
        // delete this.rapport.Personnes;

        if (this.rapport.documents.length > 0) {
          for (var i = 0; i < this.rapport.documents.length; i++) {
            this.rapport.documents[i].files = {
              name: this.rapport.documents[i].document,
              lastModified: this.rapport.documents[i].id_document,
            };
            this.rapport.documents[i].chemin =
              process.env.VUE_APP_BASE_URL + this.rapport.documents[i].chemin;
          }
        }

        console.log("rapport ",this.rapport);        
      });
    },
    deleteRappel(rappel) {
      console.log(rappel, "rapport rappels")
      api_rappels.closeRappel(rappel.id_rappel).then((res) => {
          api.getRappelsRapport(this.$route.params.id_entreprise, this.$route.params.id_rapport).then((res) => {
            this.rapport.Rappels = res.data.rappels
          })
          console.log(res, 'res')
      });
    },
  },
  created() {
    api_user.getCommerciaux().then((res) => {
      // console.log(res, 'getCommerciaux')
      this.utilisateurs = res.data.users;
    });

    api_entreprise.getUserEntreprise(this.$route.params.id_entreprise).then((res) => {
      this.$store.commit("updateTypeClientEntreprise", res.data.entreprise.type_client);
    });
    if (this.$route.name == "update_rapport") {
      this.titlePage = "MODIFIER UN RAPPORT";
      this.mode = "modification";
      this.getRapport(this.$route.params.id_rapport);
    }
     if (this.$route.name == "view_rapport") {
      this.titlePage = "VOIR UN RAPPORT";
      this.mode = "visualisation";
      this.getRapport(this.$route.params.id_rapport);
    }
  },
};
</script>
<style>
</style>