<template lang="">
  <div class="component">
       <v-row class="mb-4 mt-0" align="center" align-self="center">
      <v-col>
          <h1 class="color_white">VOS CHIFFRES</h1>
      </v-col>
      
    </v-row >
      <!-- <h1 class="color_white title_chiffre">VOS CHIFFRES</h1> -->
    <v-container class="height-container-chiffres">
      <v-row no-gutters>
        <v-col cols="8">
          <v-card class="pa-2 color_text" tile outlined>
            <div class="d-flex row align-center ">
              <img class="logo" src="@/assets/logoRetardCommande.png" />
              <div class="d-flex flex-column align-start bold font_size_13">
                <div>RETARDS</div>
                <div>DE COMMANDE</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex flex-column align-end">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{retard}}
          </v-card>
          <v-btn class="minuscule" text color="#fff" :to="{ name: 'retards' }">
            consulter
          </v-btn>
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>
    <v-container class="height-container-chiffres">
      <v-row no-gutters>
        <v-col cols="8">
          <v-card class="pa-2 color_text" tile outlined>
            <div class="d-flex row align-center ">
              <img class="logo" src="@/assets/logoRetardCommande.png" />
              <div class="d-flex flex-column align-start bold font_size_13">
                <div>LITIGES</div>
                <div>DE COMMANDE</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex flex-column align-end">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{litige}} %
          </v-card>
          <v-btn v-if="canModif == 1" class="minuscule" text color="#fff" :to="{ name: 'litiges' }">
            consulter
          </v-btn>
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>
    <v-container class="height-container-chiffres">
      <v-row no-gutters>
        <v-col cols="8">
          <v-card class="pa-2" tile outlined>
       
              <div class="d-flex row align-center ">
                <div class="logo_retour">
                  <img class="logo" src="@/assets/retourencours.png" />
                </div>
                <div class="d-flex flex-column align-start bold font_size_13">
                  <div class="color_text">RETOURS EN COURS</div>
                </div>
              </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{retours}}
          </v-card>
           <v-btn class="minuscule" text color="#fff" :to="{ name: 'retoursEnCours' }">
       consulter
      </v-btn>
          <!-- <div :to="{ name: 'home' }"  class="color_text consulter font_12">consulter</div> -->
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>

        <v-container class="height-container-chiffres">
      <v-row no-gutters>
        <v-col cols="8">
          <v-card class="pa-2" tile outlined>
       
              <div class="d-flex row align-center ">
                <div class="logo_retour">
                  <img class="logo" src="@/assets/pretsclients.png" />
                </div>
                <div class="d-flex flex-column align-start bold font_size_13">
                  <div class="color_text">PRETS CLIENTS</div>
                </div>
              </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{pretsClients}}
          </v-card>
           <v-btn class="minuscule" text color="#fff" :to="{ name: 'prets' }">
       consulter
      </v-btn>
          <!-- <div :to="{ name: 'home' }"  class="color_text consulter font_12">consulter</div> -->
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>

    <v-container class="height-container-chiffres">
      <v-row no-gutters class="mb-4">
        <v-col cols="8">
          <v-card class="pa-2 color_text" tile outlined>
            <div class="d-flex row align-center ">
              <img class="logo" src="@/assets/priseCommandeJour.png" />
              <div class="d-flex flex-column align-start bold font_size_13">
                <div>PRISE DE COMMANDE</div>
                <div>HEBDOMADAIRE</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex justify-end color_text bold">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{priseCommande.toFixed(1)}}
          </v-card>
          <div class="font_12">k€</div>
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>

    <v-container class="height-container-chiffres">
      <v-row no-gutters class="mb-4">
        <v-col cols="8">
          <v-card class="pa-2 color_text" tile outlined>
            <div class="d-flex row align-center ">
              <img class="logo" src="@/assets/priseCommandeJour.png" />
              <div class="d-flex flex-column align-start bold font_size_13">
                <div>EN-COURS MENSUEL</div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" class="d-flex justify-end color_text bold">
          <v-card class="pa-0 bold color_text chiffre_size" tile outlined>
            {{priseCommandeEnCours.toFixed(1)}}
          </v-card>
          <div class="font_12">k€</div>
        </v-col>
      </v-row>
      <div class="line"></div>
    </v-container>

    <v-container>
            <v-btn
        @click="linkToProjetBlocFore()"
        rounded
        block
        x-large
        class="mt-5 "
      >
        <img
          class="logo_marg_left logo_marg_right mr-2"
          src="@/assets/logo_stat.png"
        />
          GESTION DES PROJETS BLOC FOR&Eacute;
      </v-btn>
      <v-btn
        @click="linkToStat()"
        rounded
        block
        x-large
        class="mt-5 "
      >
        <img
          class="logo_marg_left logo_marg_right mr-2"
          src="@/assets/logo_stat.png"
        />
          VOIR TOUTES MES STATISTIQUES
      </v-btn>
      <v-btn
        @click="linkToSuiviVisites()"
        rounded
        block
        x-large
        class="mt-5 "
      >
        <img
          class="logo_visites"
          src="@/assets/logo_visites-svg.png"
        />
          SUIVI DES VISITES
      </v-btn>
            <v-btn
        @click="linkToSuiviRetard()"
        rounded
        block
        x-large
        class="mt-5 "
      >
        <img
          class="logo_visites"
          src="@/assets/logo_retard-svg.png" 
        />
          SUIVI DES RETARDS
      </v-btn>
      <!-- <v-btn
        href="https://www.planningpme.fr/mon-compte/sign"
        rounded
        target="true"
        block
        x-large
        class="mt-5"
      >
        <img class="logo logo_marg_left mr-2" src="@/assets/logo_planning.png" />
          PLANNING DE PRODUCTION
      </v-btn> -->
   
      <v-btn
        href="http://achat.f-t-a.com/" 
        v-if="(this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 1 || this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role == 3 "
        rounded
        target="true"
        block
        x-large
        class="mt-5"
        
      >
        <img class="logo logo_marg_left mr-2" src="@/assets/logiciel_achat.png" />
          LOGICIEL ACHAT
      </v-btn>
      </v-container>
  </div>
</template>
<script>
import api from "../../api/user";

export default {
  data() {
    return {
      projets: 0,
      priseCommande:0,
      priseCommandeEnCours:0,
      retard:0,
      retours:0,
      pretsClients: 0,
      litige:0,
      canModif: 0,
      currentRole:this.$store.state.AsUser.id != "" ?this.$store.state.AsUser.id_role:this.$store.state.user.id_role
    };
  },
  methods: {
    linkToPlanning() {
      window.location.href = "https://www.planningpme.fr/mon-compte/sign";
    },
    linkToProjetBlocFore() {
      this.$router.push({ path: "/projet_bloc_fore" });
    },
    linkToStat() {
      this.$router.push({ path: "/report" });
    },
    linkToSuiviVisites() {
      this.$router.push({ path: "/visites" });
    },
    linkToSuiviRetard() {
      this.$router.push({ path: "/retards_report" });
    },
    CanModified(){
      if(
      (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 1 ||  (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 3 )
      {
        this.canModif = 1
      }
    },
  },
  created() {
    api.getChiffres(this.$store.state.AsUser.id_utilisateur?this.$store.state.AsUser.id_utilisateur:'').then((res) => {
      console.log('mes chiffres',res.data)
      this.projets = res.data.nb_projet?res.data.nb_projet:0;
      this.priseCommande = res.data.prise_commande?res.data.prise_commande:0;
      this.priseCommandeEnCours = res.data.priseCommandeEnCours?res.data.priseCommandeEnCours:0;
      this.retard = res.data.retard?res.data.retard:0;
      this.retours = res.data.retour?res.data.retour:0;
      this.pretsClients = res.data.pretsClients?res.data.pretsClients:0;
      this.litige = res.data.litige>0 && res.data.litige != 'null'?res.data.litige:0;    });
      this.CanModified()

   
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.component{
  background: linear-gradient(215deg,#0B87BA,#06445D);
  height: 100vh;
}

.height-container-chiffres {
  height: 10.02vh;
}

.line {
  border-top: 1px solid #2493c1;
  // margin-top: 6%;
  // margin-bottom: 6%;
}
.tal {
  text-align: left;
}
.color_text {
  color: white;
}
.bold {
  font-weight: bold;
}
.logo_retour {
  margin-top: -6px;
}
.v-card.v-sheet.v-sheet--outlined.theme--light.rounded-0 {
  background-color: transparent;
  border: none;
}
.logo {
  margin-right: 4px;
}
.button {
  width: 93%;
  height: 57px !important;
  margin: 4%;
}
.text_color_blue {
  color: #0b87ba;
}
.logo_marg_right {
  margin-right: 4px;
}
.logo_marg_left {
  width: 28px;
}
.logo_visites {
  position: relative;
  right: 10px;
}
.color_white {
  color: white;
}
.title_chiffre {
  font-size: 1.3rem;
  padding-bottom: 16%;
}
.font_size_13 {
  font-size: 0.8rem;
  letter-spacing: normal;
}
.chiffre_size {
  font-size: 1.4rem;
}
.col.col-8 {
  display: flex;
  align-items: center;
}
.font_12 {
  font-size: 0.7rem;
}
.font_10 {
  font-size: 0.9rem;
}
.consulter {
  cursor: pointer;
}
.v-btn.minuscule{
  text-transform: lowercase;
  font-weight: normal;
  padding-right: 0;
  font-size: 0.7rem;
}
.v-btn.minuscule::before:hover{
  background-color: transparent;
}
.minuscule.v-btn:hover::before {
	opacity: 0;
}
button.d-flex.row.align-center.justify-start.button.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--rounded.theme--light.v-size--default {
  padding: 0px 10px;
}
.v-btn{
  justify-content: start;
  font-weight: bold;
  font-size: 0.75rem;
  color: $background-color-blue;
  letter-spacing: 0;
}

@media screen and (max-width: 1260px) {
  .line {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 1024px) {
  .font_size_13 {
    font-size: 0.8em;
  }
  .chiffre_size {
    font-size: 1rem;
  }
  .font_12 {
    font-size: 0.8em;
  }
  .line {
    margin-top: 1%;
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 960px) {
  .font_size_13 {
    font-size: 0.8em;
  }
  .chiffre_size {
    font-size: 0.8rem;
  }
  .font_12 {
    font-size: 0.8em;
  }
  .v-btn{
  font-size: 0.60rem;
}
}
</style>
