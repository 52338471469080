<template>
  <v-overlay id="popupComponent" :value="dialog">
    <v-card width="70vW" :height="height" rounded="xl" color="#fff" light>
      <v-col v-if="showClose == 'true'">
        <img
          id="cross"
          class="pl-2"
          src="@/assets/close.png"
          @click="$emit('emitDialog', false), (showErrorValidationMsg = false)"
        />
      </v-col>
      <v-container>
        <v-row>
          <v-col cols="12" lg="12" xl="10" class="d-flex">
            <v-col cols="3">
              <span class="font-weight-bold font-weight-medium" style="font-size: 1.3rem;">{{ numero_devis }}</span>
            </v-col>
            <v-col cols="2">
              <span class="font-weight-bold font-weight-medium" style="font-size: 1.3rem;">Date de relance</span>
            </v-col>
            <v-col
              cols="7"
              class="text-center d-flex align-center justify-center"
            >
              <span class="font-weight-bold" style="font-size: 1.3rem;">Commentaires</span>
            </v-col>
          </v-col>
        </v-row>

        <v-container
          v-for="item in devisParamsValues"
          :key="item.id_devis_param_champ"
        >
          <v-row dense no-gutters>
            <v-col cols="12" lg="12" xl="10" class="d-flex py-0">
              <v-col
                cols="2"
                class="text-center d-flex align-center justify-center"
              >
                <span class="">{{ item.libelle }}</span>
              </v-col>

              <v-col
                cols="1"
                class="text-center d-flex align-center justify-center"
              >
                <v-checkbox
                  v-model="item.value"
                  @click="uncheckOtherBoxes(item)"
                  light
                ></v-checkbox>
              </v-col>

              <v-col
                cols="2"
                class="text-center d-flex align-center justify-center"
              >
                <v-text-field v-if="item.id_devis_param_champ === 1 || item.id_devis_param_champ === 2"  single-line outlined v-model="item.date" hide-details dense ></v-text-field>
              </v-col>

              <v-col cols="7">
                <v-textarea
                  v-model="item.commentaire"
                  class="mr-4 pt-2"
                  row-height="10"
                  outlined
                  dense
                  auto-grow
                  hide-details
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>
        </v-container>

        <v-row dense no-gutters v-if="showErrorValidationMsg">
          <v-col
            cols="12"
            class="text-center d-flex align-center justify-center"
          >
            <p style="color: red">
              Veuillez cocher une case et saisir un commentaire
            </p>
          </v-col>
        </v-row>

        <v-row dense no-gutters>
          <v-col
            cols="12"
            class="text-center d-flex align-center justify-center"
          >
            <v-btn color="#FF9900" class="mr-4" rounded @click="saveValues()">
              Enregistrer
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- <template v-slot:test> 
                  <p> dsfdf </p>
              </template> -->
  </v-overlay>
</template>
<script>
import api from "@/api/devis.js";
import moment from "moment";

export default {
  props: [
    "text",
    "numero_devis",
    "dialog",
    "actions",
    "heightProp",
    "widthProp",
    "showClose",
  ],
  data() {
    return {
      height: "",
      width: "",
      devisParamsValues: [],
      showErrorValidationMsg: false,
    };
  },

  methods: {
    uncheckOtherBoxes(item) {
      console.log(item, "item uncheckbox");
      if (item.value === false) {
        item.date = "";
      }else {
        item.date = moment().format("L")
      }
      this.devisParamsValues.forEach((element) => {
        if (element.id_devis_param_champ !== item.id_devis_param_champ) {
          element.value = false;
          element.date = "";
        }
      });
    },
    saveValues() {
      console.log(this.devisParamsValues, "saveValues");
      var result = this.devisParamsValues.filter(
        (element) => element.value == true && element.commentaire != ""
      );
      if (result.length == 0) {
        this.showErrorValidationMsg = true;
      } else {
        this.showErrorValidationMsg = false;
        result[0]["numero_devis"] = this.numero_devis;
        console.log(result[0], "to submit");
        result[0]["date"] = result[0]["date"] == "" ? null : result[0]["date"];
        console.log("🚀 ~ file: devisOverlay.vue:154 ~ saveValues ~ result[0]:", result[0])

        api.saveDevisParams(result[0]).then((res) => {
          console.log(res.data, "save devis params");
          this.$emit("emitDialog", false);
          this.getDevisParams(this.numero_devis);
        });
      }
    },

    getDevisParams(numero_devis) {
      api.getDevisParams(numero_devis).then((res) => {
        console.log(res.data, "get devis params");
        this.devisParamsValues = res.data.devisParamsValues;
        console.log(this.devisParamsValues);
      });
    },
  },

  watch: {
    numero_devis(new_value) {
      this.getDevisParams(new_value);
    },
  },

  mounted() {
    if (this.heightProp == undefined || this.widthProp == undefined) {
      this.height = "auto";
      this.width = "auto";
    } else {
      (this.height = this.heightProp), (this.width = this.widthProp);
    }
  },
};
</script>

<style lang="scss" scoped>
#popupComponent {
  h1 {
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
    line-height: 3rem;
    font-size: 1.5em;
  }
  .actions {
    padding: 2% 5%;
    font-weight: bold;
    font-size: 1.2em;
  }
  #cross {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  ::v-deep .v-btn__content {
    color: white;
  }

  @media screen and (max-width: 1024px) {
    .v-card {
      width: 80vw !important;
    }
    .v-btn {
      width: 15vw !important;
    }
  }
}
</style>