import axios from 'axios';

const RESSOURCE_NAME = 'documents';

export default {
    uploadDocument(id, data, config){
        console.log("🚀 ~ uploadDocument ~ data:", data)
        // var personnes = Object.fromEntries(data.personnes)
        return axios.post(`${RESSOURCE_NAME}/upload/`+ id, data , config)
    },

    uploadTemp(document) {
        const formData = new FormData();
        formData.append('document', document);
        
        return axios.post(`${RESSOURCE_NAME}/uploadTemp`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    uploadTempDocument(id, data, config){
        // var personnes = Object.fromEntries(data.personnes)
        return axios.post(`${RESSOURCE_NAME}/upload/`+ id, data , config)
    },

    deleteDocument(id){
        // var personnes = Object.fromEntries(data.personnes)
        return axios.delete(`${RESSOURCE_NAME}/delete/`+ id)
    },
    createDirectory(data){
        return axios.post(`${RESSOURCE_NAME}/directories/create`, data)

    },
    uploadFile(data,id,config){
        return axios.post(`${RESSOURCE_NAME}/upload/entreprise/`+id, data,config)

    },
    download_Documents(id){
        return axios.get(`${RESSOURCE_NAME}/download/`+id,{responseType: 'arraybuffer'})

    },
    getBase64File(id){
        console.log("🚀 ~ getBase64File ~ id:", id)
        return axios.get(`${RESSOURCE_NAME}/base64/`+id)

    },
    deleteDirectory(id,data){
        return axios.post(`${RESSOURCE_NAME}/delete/directory/${id}`,data)
    }
}