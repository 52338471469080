<template lang="">

<div>
        <!-------------------------------- BLOC DESCRIPTION -------------------------------->
      <v-col v-if="tab == 'Projet'" cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
            
          >
            DESCRIPTION
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10"  class="alignement_input ">
       <div  v-if="!projet.bloc_fore" class="text-left">DESCRIPTION* </div>
       <div  v-if="projet.bloc_fore && tab == 'Projet'" class="text-left">DESCRIPTION COMMERCIALE*</div>
        <v-textarea v-if="mode !== 'visualisation' && tab == 'Projet'" outlined v-model="projet.description" hide-details> </v-textarea>
         <div  v-if="mode == 'visualisation' && tab == 'Projet'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{projet.description}} </div>
                  <!-- message d'erreur -->
                  <!-- {{$v.projet.description.$error}} -->
          <div class="errorMsg" v-if="$v.projet.description.$error && $v.projet.description.required == false && tab == 'Projet'">
            Renseignez une description pour le projet.
          </div>

          <div class="errorMsg" v-if="$v.projet.description.$error && ($v.projet.description.required == true && $v.projet.description.minLength == false) ">
            5 caractères au minimum.
          </div>

      </v-col>


      <v-col v-if="!projet.bloc_fore" cols="12" sm="2" class="pt-0 ">
        <div class="text-left">BUDGET</div>
        <v-text-field v-if="mode !== 'visualisation'" dense  label="" single-line outlined v-model="projet.budget_potentiel"   ></v-text-field>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.budget_potentiel}} </div>
      </v-col>

      <!-- <--------------------------- bloc foré -------------------------> 

      <div v-else >
        <v-col v-if="tab == 'Projet'" cols="12" sm="10" class="pt-0 ">
          <div class="text-left">APPLICATION OU TYPE DE MACHINE*</div>
          <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.application" hide-details dense ></v-text-field>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{projet.application}} </div>

          <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.projet.application.$error && $v.projet.application.required == false">
            Renseignez l'application ou le type de machine pour le projet.
          </div>

          <div class="errorMsg" v-if="$v.projet.application.$error && ($v.projet.application.required == true && $v.projet.application.minLength == false) ">
            5 caractères au minimum.
          </div>
          <!-- message d'erreur -->
        </v-col>

        <v-container v-if="tab == 'Cahier_des_charges'">
          <v-col cols="12" sm="10"  class="alignement_input ">
        <div  v-if="projet.bloc_fore" class="text-left">DECRIVEZ LE ROLE ET LE FONCTIONNEMENT DU BLOC*</div>
          <v-textarea v-if="mode !== 'visualisation'" outlined v-model="projet.fonctionnement_bloc" hide-details> </v-textarea>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold" style="overflow-wrap: break-word;">{{projet.fonctionnement_bloc}} </div>
                    <!-- message d'erreur -->
            <div class="errorMsg" v-if="$v.projet.fonctionnement_bloc.$error && $v.projet.fonctionnement_bloc.required == false">
              Renseignez un fonctionnemen bloc pour le projet.
            </div>

            <div class="errorMsg" v-if="$v.projet.fonctionnement_bloc.$error && ($v.projet.fonctionnement_bloc.required == true && $v.projet.fonctionnement_bloc.minLength == false) ">
              5 caractères au minimum.
            </div>

        </v-col>


        <v-row class="pl-3 mt-3">
            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">DEBIT MAX*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.debit_max" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.debit_max}} </div>

              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.debit_max.$error && $v.projet.debit_max.required == false">
                Renseignez le débit maximal pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>

            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">PRESSION MAX*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.pression_max" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.pression_max}} </div>
             
              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.pression_max.$error && $v.projet.pression_max.required == false">
                Renseignez la pression maximale pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>
        </v-row>

        <v-row class="pl-3 mt-3">
          <v-col cols="12" sm="10" class="pt-0 ">
            <div class="text-left">PROTECTION DE SURFACE SPECIFIQUE*</div>
            <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.protection" hide-details dense ></v-text-field>
            <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.protection}} </div>
            <div v-if="mode !== 'visualisation'" class="text-left mt-2 mb-2 font-weight-bold">Si aluminium -> Si possible pas de traitement <br> Si acier -> si possible peinture bleu au lieu de zingage ou autre traitement sous traité.</div>
            
            <!-- message d'erreur -->
            <div class="errorMsg" v-if="$v.projet.protection.$error && $v.projet.protection.required == false">
              Renseignez la protection pour le projet.
            </div>

            <div class="errorMsg" v-if="$v.projet.protection.$error && ($v.projet.protection.required == true && $v.projet.protection.minLength == false) ">
              5 caractères au minimum.
            </div>
            <!-- message d'erreur -->
          </v-col>
        </v-row>

        <v-row class="pl-3 mt-3">
            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">QTE ANNUELLE*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.qte_annuelle" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.qte_annuelle}} </div>

              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.qte_annuelle.$error && $v.projet.qte_annuelle.required == false">
                Renseignez la quantité  annuelle pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>

            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">QTE PAR LIVRAISON*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.qte_livraison" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.qte_livraison}} </div>
             
              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.qte_livraison.$error && $v.projet.qte_livraison.required == false">
                Renseignez la quantité par livaison pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>

                        <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">PRIX OBJECTIF*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.prix_objectif" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.prix_objectif}} </div>

              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.prix_objectif.$error && $v.projet.prix_objectif.required == false">
                Renseignez le prix objectif pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>

                        <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">BUDGET GLOBAL*</div>
              <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.budget_potentiel" hide-details dense ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.budget_potentiel}} </div>

              <!-- message d'erreur -->
              <div class="errorMsg" v-if="$v.projet.budget_potentiel.$error && $v.projet.budget_potentiel.required == false">
                Renseignez le budget global pour le projet.
              </div>
              <!-- message d'erreur -->
            </v-col>
        </v-row>

        <v-row class="pl-3 mt-3">
            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">REGLAGE DES PRESSIONS</div>
                  <v-select v-if="mode !== 'visualisation'" v-model="projet.reglage_pression" :items="options_reglage_pression" outlined dense></v-select>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.reglage_pression}} </div>
            </v-col>
            <v-col cols="12" sm="2" class="pt-0 ">
              <div class="text-left">FREQUENCE DES TESTS</div>
                  <v-select v-if="mode !== 'visualisation'" v-model="projet.frequence_test" :items="options_frequence_test" outlined dense></v-select>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.frequence_test}} </div>

            </v-col>
        </v-row>


        </v-container>
        
      </div>

      <!-- <--------------------------- bloc foré -------------------------> 


      <!-------------------------------- BLOC DESCRIPTION -------------------------------->

</div>

</template>
<script>
import { minLength, requiredIf, required } from "vuelidate/lib/validators";
export default {
  props: {
    projet: {
      type: Object,
      required: true,
    },
    mode: [String],
    tab: [String],
    // value: {
    //   type: String,
    //   default: ""
    // },
    // v: {
    //   type: Object,
    //   required: true,
    // }
    // disabled: {
    //   type: Boolean,
    //   default: false
    // }
  },
  components: {},
  data() {
    return {
    options_reglage_pression: [
      'Oui',
      'Non'
    ],
    options_frequence_test: [
      'Pas de tests',
      'Toutes les pièces',
      '1 sur 2',
      '1 sur 3',
      '1 sur 5',
      '1 sur 10'
    ]
    };
  },
  validations: {
    projet: {
      description: {
        required,
        minLength: minLength(5),
      },
      application: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
        minLength: minLength(5),
      },
      fonctionnement_bloc: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
        minLength: minLength(5),
      },
      debit_max: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
      pression_max: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
      protection: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
        minLength: minLength(5),
      },
      qte_annuelle: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
      qte_livraison: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
      prix_objectif: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
      budget_potentiel: {
        required: requiredIf(function () {
          return this.projet.bloc_fore == true;
        }),
      },
    },
  },
  computed: {},
  watch: {},
  methods: {},
  async created() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.font_color {
  font-weight: bold;
  color: #ffffff;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}
</style>
