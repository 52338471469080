<template lang="">
  <div class="d-flex">
   
      <!-------------------------------- BLOC PIECES JOINTES-------------------------------->
      <v-col cols="12" sm="10" class="pb-0 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="12"
            lg="12"
            xl="12"
            class="pa-0 font_color text-left"
          >
            PIECES JOINTES (à renommer suivant exemple : 3D - 20/09/2021)

            <v-btn v-if="mode !== 'visualisation' && currentDirectory !== ''"
                class="btnBold ml-16 "
                color="#FF9900"
                dark
                rounded
                @click="onButtonClick"
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>
                Ajouter une piece jointe
            </v-btn>
                  <input ref="uploader" class="d-none" type="file" accept="*" @change="onFileChanged" >
          </v-col>

          



        </v-toolbar>

                  <v-col
            cols="12"           
            class="pa-0 font_color text-left"
          >
                 
          <!-- <v-breadcrumbs
              :items="breadcrumbs"
              divider=">"
              active-class="active-item"
            >
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :href="item.href"
                  
                @click="updateDirectory(item)"
                > <span v-if="!item.home" :class="{ disable: item.disable,active:!item.disable } " 
                class="white--text font-weight-bold "
               
                >
                    {{ item.text}} 
                </span> 
                <v-icon v-if="item.home">fa fa-home</v-icon>         
                </v-breadcrumbs-item>
              </template>
                
            </v-breadcrumbs> -->

              <v-breadcrumbs :items="breadcrumbs" divider=">">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                    :href="item.href"
                    :disabled="item.disabled"
                    @click="updateDirectory(item)"
                  >{{ item.text.toUpperCase() == "SCHÉMAS HYDRAULIQUES" && bloc_fore ? "CAHIER DES CHARGES" : item.text.toUpperCase() }}
                  <v-icon v-if="item.home">fa fa-home</v-icon>  
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
          </v-col>

        <div class="folder-flex">
          <div class="folder" v-for="(directory) in directories" :key="directory.name">
            <div class="d-inline"  >
              <div  >
                <img 
                  src="@/assets/folder.png"
                @click="updateCurrentDirectory(directory)"
                  
                />
              </div>
              <div class="font-weight-bold" >
 {{directory.name == "Schémas hydrauliques" && bloc_fore ? "Cahier des charges" : directory.name}} 
                </div>
               <div class="text-caption">fichiers : {{directory.nb}}</div>
            </div>
          </div>
        </div>



      <UploaderFiles v-if="currentDirectory !== ''" :currentDirectory="currentDirectory"  :documents="documents" @emitFile=" fileIsFromDrag = true, onFileChanged($event)" :mode="mode"  ref="UploaderFiles"/>
      </v-col>

  </div>
</template>
<script>
// import api_doc from "@/api/document";
import UploaderFiles from "@/components/Clients/UploaderFiles";
export default {
  props: {
    documents: {
      type: Object,
      default: function () {
        return { message: 'hello' }
      }
    },
    bloc_fore: [Boolean],
    mode: [String],
  },
  components: {UploaderFiles},
  data() {
    return {
      element: [],
      fileIsFromDrag: false,
      documentToDelete: null,
      indexToDelete: null,
      dialog: false,
      selectedFile: null,
      isSelecting: false,
      breadcrumbs: [
      {
        text: '',
        disabled: false,
        home: true
      }
    ],
     directories_base: [
       {
         name: "Mails Importants",
         value: "mails_importants",
         nb: 0
       },
       {
         name: "Schémas hydrauliques",
         value: "schemas_hydrauliques",
         nb: 0
       },
              {
         name: "Photos",
         value: "photos",
         nb: 0
       },
              {
         name: "Offres fournisseur",
         value: "offres_fournisseur",
         nb: 0
       },
              {
         name: "3D/2D",
         value: "_3d_2d",
         nb: 0
       },
              {
         name: "Autres documents",
         value: "autres",
         nb: 0
       },

     ],
     directories: [],
     currentDirectory: ''
  
      // documents: []
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if(this.fileIsFromDrag == true) {
        this.selectedFile = e
      }else {
        this.selectedFile = e.target.files[0];
      }

      // for (var [key, value] of Object.entries(this.documents)) {
      //   console.log(`${key}: ${value}`);

      //   this.selectedFile.subdirectory = key
      //   window['this.documents'+key].push({
      //     files: this.selectedFile
      //   });
      //   console.log("dynamic add_element", value)
      // }

      // for (var i = 0; i <  Object.keys(this.documents).length; i++) {
      //     console.log("key", Object.keys(this.documents[i]))
      //     if ( Object.keys(this.documents[i]) == this.currentDirectory) {
      //       this.selectedFile.subdirectory = this.currentDirectory
      //       this.documents[i].push({
      //         files: this.selectedFile
      //       });
      //     }

      // }

      

      if ( this.currentDirectory == "mails_importants") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents.mails_importants.push({
          files: this.selectedFile
        });

      }

      if ( this.currentDirectory == "schemas_hydrauliques") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents.schemas_hydrauliques.push({
          files: this.selectedFile
        });

      console.log("schemas_hydrauliques add_element", this.documents.schemas_hydrauliques);
      }


      if ( this.currentDirectory == "photos") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents.photos.push({
          files: this.selectedFile,
          subdirectory: this.currentDirectory
        });

      console.log("photos add_element", this.documents.photos);
      }

      if ( this.currentDirectory == "offres_fournisseur") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents.offres_fournisseur.push({
          files: this.selectedFile
        });

      console.log("offres_fournisseur add_element", this.documents.offres_fournisseur);
      }

      if ( this.currentDirectory == "_3d_2d") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents._3d_2d.push({
          files: this.selectedFile
        });

      console.log("_3d_2d add_element", this.documents._3d_2d);
      }

      if ( this.currentDirectory == "autres") {
        this.selectedFile.subdirectory = this.currentDirectory
        this.documents.autres.push({
          files: this.selectedFile
        });

      console.log("autres add_element", this.documents.autres);
      }

      this.fileIsFromDrag = false;

    },
    updateDirectory(item) {
      // Si la maison est cliqué on remet les repertoires qui ont été vidés
      if ( item.home ) {
        this.currentDirectory = ''
        this.directories = this.directories_base
        // Supprime le dernier element de navigation
        if ( this.breadcrumbs.length > 1) {
          this.breadcrumbs.pop()
        }
      }
    },
    updateCurrentDirectory(directory) {
      // Pour retirer l'affichage des repertoires
      this.directories = []
      // Pour afficher le lien de navigation
      this.breadcrumbs.push( {
        text: directory.name, disabled: true
      })
      this.currentDirectory = directory.value
    }
  },
  mounted() {
    this.directories = this.directories_base
  },
  watch: {
    documents: {
      handler: function () {
        var mails_importants_directory = this.directories_base.find(
          (element) => element.value == 'mails_importants'
        );
        var schemas_hydrauliques_directory = this.directories_base.find(
          (element) => element.value == 'schemas_hydrauliques'
        );
        var photos_directory = this.directories_base.find(
          (element) => element.value == 'photos'
        );
        var offres_fournisseur_directory = this.directories_base.find(
          (element) => element.value == 'offres_fournisseur'
        );
        var _3d_2d_directory = this.directories_base.find(
          (element) => element.value == '_3d_2d'
        );
        var autres_directory = this.directories_base.find(
          (element) => element.value == 'autres'
        );
        mails_importants_directory.nb = this.documents.mails_importants.length
        schemas_hydrauliques_directory.nb = this.documents.schemas_hydrauliques.length
        photos_directory.nb = this.documents.photos.length
        offres_fournisseur_directory.nb = this.documents.offres_fournisseur.length
        _3d_2d_directory.nb = this.documents._3d_2d.length
        autres_directory.nb = this.documents.autres.length


      },
      deep: true
    }
  },
};
</script>
<style scoped>
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

::v-deep .v-breadcrumbs__item {
	align-items: center;
	display: inline-flex;
	text-decoration: none;
	transition: .3s cubic-bezier(.25,.8,.5,1);
	color: #FF9900;
}

.active {
  cursor: pointer;
  font-weight: bold;
}
::v-deep .v-breadcrumbs__divider{
  font-weight: bold;
  color: blue!important;
}
.disable{
  font-weight:normal!important
}
.v-breadcrumbs li .v-icon {
	font-size: 16px;
	color: #FF9900;
  cursor: pointer;
}
::v-deep .linkProjet{
  list-style-type:square
 
}
.linkProjet a{
   
    color:#4B4B4B;
 
}
a div:nth-child(2){
  color:#4B4B4B;
  
}
a.linkProjet{
text-decoration:none;
}

.folder {
  cursor: pointer;
}

.folder-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
