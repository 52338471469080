<template>
  <v-row>
    <v-col cols="12">
      <Title :titlePage="titlePage" />
    </v-col>

    <v-container>
      <!-- <ContactForm :contact="contact" @contact="createOrUpdadateContact" /> -->
      <ProjetForm
        :projet="projet"
        :mode="mode"
        :utilisateurs="utilisateurs"
        @emitProjet="createOrUpdateProjet"
        @closeRappel="closeRappel"
      />
      <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />
    </v-container>
  </v-row>
</template>
<script>
import Title from "@/components/shared/Title";
import ProjetForm from "@/components/Clients/ProjetForm";
import Popup from "@/components/shared/Popup";

import api from "@/api/projet";
import api_doc from "@/api/document";
import api_rappels from "@/api/rappel";
import api_user from "@/api/user";

export default {
  components: {
    Title,
    ProjetForm,
    Popup,
  },
  data() {
    return {
      projet: {
        id_projet: null,
        nom_projet: "",
        date_projet: "",
        description: "",
        commentaire_projet_obtenu: "",
        budget_potentiel: "",
        projet_obtenu: null,
        bloc_fore: false,
        application: "",
        fonctionnement_bloc: "",
        debit_max: "",
        pression_max: "",
        protection: "",
        qte_annuelle: "",
        qte_livraison: "",
        prix_objectif: "",
        etapes: [],
        outils_specifiques: [],
        outils_specifiques_keys: [],
        cartouches_non_stockees: [],
        cartouches_non_stockees_keys: [],
        showOutilsForm: false,
        showCartouchesForm: false,
        id_utilisateur: this.$store.state.user.id_utilisateur,
        rappels: [],
        rows: [],
        projet_a_articles: [],
        documents: {
          mails_importants: [],
          schemas_hydrauliques: [],  
          photos: [], 
          offres_fournisseur: [], 
          _3d_2d: [], 
          autres: [], 
        },
        commentaires: [],
        personnes: [],
        commentaire_: "",
      },
      rappel: {
        urgent: false,
        date_rappel: "",
        commentaire: "",
      },
      utilisateurs: [],
      titlePage: "NOUVEAU PROJET",
      dialog: false,
      messageCreation: "",
      actions: {
        route: [
          {
            btn: "OK",
            routeName: "client",
            params: { id_entreprise: this.$route.params.id_entreprise },
          },
        ],
      },
      mode: "creation",
    };
  },

  methods: {


    closeRappel(rappel) {
      console.log(rappel, "projetEntreprise rappel")
      api_rappels.closeRappel(rappel.id_rappel).then((res) => {
          api.getRappelsProjet(this.$route.params.id_entreprise, this.$route.params.id_projet).then((res) => {
            this.projet.rappels = res.data.rappels
          })
          console.log(res, 'res')
      });
    },

    createOrUpdateProjet(projet) {
      // contact.id_entreprise = this.$route.params.id_entreprise;
      // this.projet = projet;
      if (this.mode == "creation") {
        api.createProjet(projet.projet).then((res) => {
          console.log(res);
          var id_projet = res.data.projet.id_projet;

          let data = new FormData();
          data.append("mode", "projets");
          data.append("directory", id_projet);
          data.append("id_element", id_projet);
          console.log("projet.docs", projet.docs)
          for (var j = 0; j < projet.docs.length; j++) {
            // if (this.docs[j].id_document === undefined) {
            let file = projet.docs[j].files;
            // console.log("file debug", file.subdirectory)
            data.append("subdirectory", file.subdirectory);
            data.append("documents", file);
 

          }

          api_doc
            .uploadDocument(
              this.$route.params.id_entreprise,
              data,
              projet.config
            )
            .then((res) => {
              console.log("res upload", res);
            });

          if(projet.image[0] !== undefined) {
                      data = new FormData();
            data.append("mode", "projets");
            // data.append('documents', this.documents);
              let file = projet.image[0].files;
              data.append("documents", file);
              data.append("directory", id_projet);
              data.append("id_element", id_projet);


            
              let config = {
              header: {
                "Content-Type": "multipart/form-data",
              },
            };
                api_doc.uploadDocument(id_projet, data, config).then((res) => {
                console.log("res upload", res);
              });  
          }


          this.messageCreation = "Projet sauvegardé";
          this.dialog = true;
        });
      } else if (this.mode == "modification") {
        api.updateProjet(projet.projet).then((res) => {
          console.log(res);
          var id_projet = projet.projet.id_projet;
          let data = new FormData();
          data.append("mode", "projets");
          data.append("directory", id_projet);
          data.append("id_element", id_projet);
          for (var j = 0; j < projet.docs.length; j++) {
            // if (this.docs[j].id_document === undefined) {
            let file = projet.docs[j].files;
            data.append("subdirectory", file.subdirectory);
            data.append("documents", file);

          }

          api_doc.uploadDocument( this.$route.params.id_entreprise, data, projet.config).then((res) => {
            console.log("res upload", res);
          });

          this.messageCreation = "Projet mis à jour";
          this.dialog = true;
        });
      }
    },

    getProjet(id_projet) {
      api.getProjet(id_projet).then((res) => {
        this.projet.id_projet = res.data.projet[0].id_projet;
        this.projet.nom_projet = res.data.projet[0].nom_projet;
        this.projet.date_projet = res.data.projet[0].date_projet;
        this.projet.description = res.data.projet[0].description;
        this.projet.commentaire_projet_obtenu =res.data.projet[0].commentaire_projet_obtenu;
        this.projet.budget_potentiel = res.data.projet[0].budget_potentiel;
        this.projet.projet_obtenu = res.data.projet[0].projet_obtenu;

        this.projet.bloc_fore = res.data.projet[0].bloc_fore;
        this.projet.application = res.data.projet[0].application;
        this.projet.fonctionnement_bloc = res.data.projet[0].fonctionnement_bloc;
        this.projet.debit_max = res.data.projet[0].debit_max;
        this.projet.pression_max = res.data.projet[0].pression_max;
        this.projet.protection = res.data.projet[0].protection;
        this.projet.qte_annuelle = res.data.projet[0].qte_annuelle;
        this.projet.qte_livraison = res.data.projet[0].qte_livraison;
        this.projet.prix_objectif = res.data.projet[0].prix_objectif;

        this.projet.reglage_pression = res.data.projet[0].reglage_pression;
        this.projet.frequence_test = res.data.projet[0].frequence_test;
        this.projet.longueur = res.data.projet[0].bloc_longueur;
        this.projet.largeur = res.data.projet[0].bloc_largeur;
        this.projet.hauteur = res.data.projet[0].bloc_hauteur;
        this.projet.face_6 = res.data.projet[0].face_6;
        
        this.projet.rows = (res.data.projet[0].faces === null || res.data.projet[0].faces === "[]") ? [] : JSON.parse(res.data.projet[0].faces);

        this.projet.projet_a_articles = res.data.projet[0].articles;

        this.projet.personnes = res.data.projet[0].Personnes;
        this.projet.rappels = res.data.projet[0].Rappels;
        this.projet.id_utilisateur = res.data.projet[0].id_utilisateur
        var documents = res.data.projet[0].Documents;

        if ( documents.length > 0) {
          for ( var i = 0; i < documents.length; i++) {
            documents[i].files = { name: documents[i].document, lastModified: documents[i].id_document}
            documents[i].chemin = process.env.VUE_APP_BASE_URL + documents[i].chemin
            if ( documents[i].chemin.includes("mails_importants") ) {
              this.projet.documents.mails_importants.push(documents[i])
            }
            if ( documents[i].chemin.includes("schemas_hydrauliques") ) {
              this.projet.documents.schemas_hydrauliques.push(documents[i])
            }
            if ( documents[i].chemin.includes("photos") ) {
              this.projet.documents.photos.push(documents[i])
            }
            if ( documents[i].chemin.includes("offres_fournisseur") ) {
              this.projet.documents.offres_fournisseur.push(documents[i])
            }
            if ( documents[i].chemin.includes("_3d_2d") ) {
              this.projet.documents._3d_2d.push(documents[i])
            }
            if ( documents[i].chemin.includes("autres") ) {
              this.projet.documents.autres.push(documents[i])
            }
          }
        }

        this.projet.commentaires = res.data.projet[0].Commentaires.sort(function (a, b) {
          return a.id_commentaire - b.id_commentaire;
        });
      });
    },
  },
  created() {
    api.getEtapesProjetBlocFore(this.$route.params.id_projet).then((res) => {
      this.projet.etapes = res.data.etapes;
      this.projet.outils_specifiques = res.data.outils_specifiques;
      this.projet.cartouches_non_stockees = res.data.cartouches;
      this.projet.outils_specifiques_keys = Object.keys(res.data.outils_specifiques[0]).splice(2); 
      this.projet.cartouches_non_stockees_keys = Object.keys(res.data.cartouches[0]).splice(2); 
      console.log(this.projet.cartouches_non_stockees_keys, "keys")
      this.projet.showOutilsForm = this.projet.etapes.find(obj => obj.id === 6 && obj.isChecked == true) ? true : false;
      this.projet.showCartouchesForm = this.projet.etapes.find(obj => obj.id === 7 && obj.isChecked == true) ? true : false;
    })
    api_user.getCommerciaux().then((res) => {
      // console.log(res, 'getCommerciaux')
      this.utilisateurs = res.data.users;
    });
    if (this.$route.name == "update_projet") {
      this.titlePage = "MODIFIER UN PROJET";
      this.mode = "modification";
      this.getProjet(this.$route.params.id_projet);
    }

    if (this.$route.name == "view_projet") {
      this.titlePage = "VOIR UN PROJET";
      this.mode = "visualisation";
      this.getProjet(this.$route.params.id_projet);
    }
  },
};
</script>
<style>
</style>