<template lang="">
  <div class="d-flex">
    <form @submit.prevent="submit" class="d-flex flex-column flex-grow-1">
 

      <v-container >

      <!-------------------------------- Bloc nouveau projet -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <div class="text-left">NOM PROJET (mois-année application produit1..produit2...etc)*</div>
        <v-text-field v-if="mode !== 'visualisation'" label="mois-année application produit1..produit2...etc" single-line outlined v-model="projet.nom_projet" hide-details dense ></v-text-field>
        <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.nom_projet}} </div>
            <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.projet.nom_projet.$error && $v.projet.nom_projet.required == false">
            Renseignez le nom du projet.
          </div>

          <div class="errorMsg" v-if="$v.projet.nom_projet.$error && ($v.projet.nom_projet.required == true && $v.projet.nom_projet.minLength == false) ">
            4 caractères au minimum.
          </div>

          <div class="errorMsg" v-if="$v.projet.nom_projet.$error && ($v.projet.nom_projet.required == true && $v.projet.nom_projet.maxLength == false) ">
            100 caractères au maximum.
          </div>
          <!-- message d'erreur -->
        </v-col> 

    <v-row class="mt-2" align-self="end" align="center"  no-gutters>
      <!-- <v-col cols="12" sm="10" class="pb-0  mt-10"> -->
      <v-col cols="4" sm="3" class=" pl-3 mt-1">
        <div class="text-left">
        DATE DU PROJET*
        </div>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          light
        >
          <template v-slot:activator="{ on, attrs }">

              <span v-if="mode !== 'visualisation'"
              class=" calendar-field">
                  <v-text-field 
                      :value="formatDate"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      light
                      outlined
                      hide-details
    
                    >
                                                      <template slot="append-outer">
                    <img class="ml-2" src="@/assets/calendar.png" />
                  </template>
                    </v-text-field>

              

              </span>
              <div v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{formatDate}} </div>

          </template>                                <!-- message d'erreur -->

          <v-date-picker
            v-model="projet.date_projet"
            no-title
            locale="fr-fr"
            @input="menu = false"
          ></v-date-picker>

        </v-menu>
                                  <div class="errorMsg" v-if="$v.projet.date_projet.$error">
            Renseignez la date du projet
          </div>
      </v-col>


      <v-col cols="4" sm="4" class="pl-10 mt-1">
          <div class="text-left">CREATEUR</div>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{createur}} </div>

        <v-select
          v-if="mode !== 'visualisation'"
          :items="utilisateurs"
          item-value="id_utilisateur"
          item-text="nom"
          v-model="projet.id_utilisateur"
          item-color='primary'
          class="text-color"
          hide-details
          solo
        ></v-select>

      </v-col>
    </v-row>
      <v-row class="mt-4" align-self="end" align="center"  no-gutters>
        <v-col cols="4" class="pl-3" align="left">
          <label>PROJET BLOC FORE 
            <input v-if="mode !== 'visualisation'" type="checkbox" v-model="projet.bloc_fore" style="width: 30px; height:20px;" />
            <div v-else class="text-left font-weight-bold"> {{ projet.bloc_fore ? "OUI" : "NON"}} </div>
          </label>
        </v-col>
      </v-row>

      <v-row>
      <v-col cols="12" class="pl-3" align="left">
 <!-------------------------------- Tab links -------------------------------->
      <div v-if="projet.bloc_fore == true" class="tab">
        <v-btn class="tablinks mr-1"  @click="openTab($event, 'Projet')">Projet</v-btn>
        <v-btn class="tablinks mr-1"  @click="openTab($event, 'Cahier_des_charges')">Cahier des charges</v-btn>
        <v-btn class="tablinks mr-1"  @click="openTab($event, 'Calcul_cout')">Calcul du cout</v-btn>
        <v-btn class="tablinks mr-1"  @click="openTab($event, 'Calcul_prix_de_vente')">Calcul prix de vente</v-btn>
        <v-btn class="tablinks"  @click="openTab($event, 'Offre commerciale')">Offre commerciale</v-btn>
      </div>
 <!-------------------------------- Tab links -------------------------------->
      </v-col>
      </v-row>

            <!-- Tab content -->
      <div id="Projet" class="tabcontent active" style="display: block">

    <!-------------------------------- Bloc Personnes rencontrées -------------------------------->
 <AddContacts :personnes="projet.personnes" :mode="mode" @emitPersonnes="projet.personnes = $event" ref="AddContacts"/>

  <div class="text-left pl-4" v-if="projet.personnes.length == 0">
    Au moins une personne doit être rajoutée*
  </div>


      <!-------------------------------- BLOC PERSONNES RENCONTREES -------------------------------->
      <!-------------------------------- BLOC DESCRIPTION -------------------------------->
 <ProjetBlocDescription :projet="projet" :tab="tab" :mode="mode" ref="ProjetBlocDescription" />

      <!-------------------------------- BLOC DESCRIPTION -------------------------------->

      <!-------------------------------- BLOC CHRONOLOGIE DES ETAPES -------------------------------->
        <ProjetEtapes v-if="projet.bloc_fore" :projet="projet" :mode="mode" ref="ProjetEtapes" />

      <!-------------------------------- BLOC DESCRIPTION -------------------------------->

      <!-------------------------------- BLOC COMMENTAIRE-------------------------------->
      <v-col  v-if="mode !== 'visualisation'"  cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            COMMENTAIRE
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col  v-if="mode !== 'visualisation'"  cols="12" sm="10"  class="alignement_input ">
       <div class="text-left">COMMENTAIRE</div>
        <v-textarea outlined auto-grow rows="1"  v-model="projet.commentaire_"> </v-textarea>
      </v-col>

      <!-------------------------------- BLOC COMMENTAIRE-------------------------------->
      <!-------------------------------- BLOC HISTORIQUE DES COMMENTAIRES-------------------------------->
    <div  v-if="(mode == 'modification' || mode == 'visualisation') && projet.commentaires.length > 0">
      <v-col  cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            HISTORIQUE DES COMMENTAIRES
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10"  class="text-left ">
        <div v-for="commentaire in projet.commentaires.slice().reverse()" :key="commentaire.createdAt">
          <div class="date-letter text-uppercase ">{{commentaire.User.nom}} - {{ commentaire.createdAt ? $moment(commentaire.createdAt).format("LL") : "" }}</div>
          <div class="font-italic text-h6 " style="overflow-wrap: break-word;">"{{ commentaire.commentaire }}"</div>
        </div>
      </v-col>
    </div>
      <!-------------------------------- BLOC HISTORIQUE DES COMMENTAIRES-------------------------------->
      <AddRappels  :rappels="projet.rappels" :mode="mode"  ref="AddRappels" @closeRappel="closeRappel" />
      <div class="text-left pl-3" v-if="projet.rappels.length == 0">
        Aucun rappel n'a été ajouté
      </div>
      <!-------------------------------- BLOC PIECES JOINTES-------------------------------->
       <!-- <AddDocuments  :documents="projet.documents" :mode="mode"  ref="AddDocuments"/> -->

      <AddDocumentsNew  :documents="projet.documents" :bloc_fore="projet.bloc_fore" :mode="mode"  ref="AddDocumentsNew"/>
       
       <!-------------------------------- BLOC PIECES JOINTES-------------------------------->
      <!-------------------------------- BLOC ETAT DU PROJET-------------------------------->
      <div  >
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            ETAT DU PROJET
          </v-col>
        </v-toolbar>
      </v-col>

              <v-col cols="12" sm="10" class="pb-0  ">

    
           <div class="d-flex">
            <div class="text-left mt-5">PROJET OBTENU</div>
              <v-radio-group v-if="mode !== 'visualisation'" class="ml-10" row v-model="projet.projet_obtenu">
                <v-radio label="OUI"  v-bind:value="true"></v-radio>
                <v-radio label="NON"  v-bind:value="false" ></v-radio>
              </v-radio-group>
            </div>
                          <div  v-if="mode == 'visualisation' && projet.projet_obtenu == null" class="text-left font-weight-bold">Non renseigné. </div>

            <div  v-if="mode == 'visualisation' && projet.projet_obtenu !== null" class="text-left font-weight-bold">{{projet.projet_obtenu == true ? 'OUI' : 'NON'}} </div>
          </v-col>

      <v-col cols="12" sm="10"  class="alignement_input ">
       <div v-if="projet.projet_obtenu !== null" class="text-left">COMMENTAIRE*</div>
                                 <div  v-if="mode == 'visualisation' && projet.projet_obtenu !== null && projet.commentaire_projet_obtenu !== null" class="text-left font-weight-bold">Non renseigné. </div>

        <v-textarea  v-if="projet.projet_obtenu !== null && mode !== 'visualisation'" outlined auto-grow rows="1"  v-model="projet.commentaire_projet_obtenu"> </v-textarea>
        <div  v-if="projet.projet_obtenu !== null && mode == 'visualisation'" class="text-left font-weight-bold">{{projet.commentaire_projet_obtenu}} </div>

      </v-col>
      </div>
      <!-------------------------------- BLOC ETAT DU PROJET-------------------------------->
      </div> 

      <div id="Cahier_des_charges" class="tabcontent">
        <ProjetBlocDescription :projet="projet" :tab="tab" :mode="mode" ref="ProjetBlocDescriptionCahier" />
        <AddArticles :retour_a_articles="projet.projet_a_articles" :mode="mode" :blocFore="true"/>


        <div class="mt-3">
          <div class="image-container">
            <img :src="imageUrl" class="image" :style="{ transform: 'scale(' + zoomLevel + ')' }" />
          </div>
          <div class="mt-3 mb-3">
                        <v-btn v-if="mode !== 'visualisation' && currentDirectory !== ''"
                class="btnBold ml-16 "
                color="#FF9900"
                dark
                rounded
                @click="onButtonClick"
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>
                Ajouter une image
            </v-btn>
            <input ref="uploader" class="d-none" type="file" accept="*" @change="onFileChanged" >
            <v-btn @click="zoomOut">Zoom -</v-btn>
            <v-btn @click="zoomIn">Zoom +</v-btn>
          </div>
        </div>

  <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="12"
            lg="12"
            xl="12"
            class="pa-0 font_color text-left"
          >
            {{title?title:"IMPLANTATIONS ET DIMENSIONS - indiquez les dimensions hors-tout (cartouches inclues)"}}
          </v-col>

    <v-col cols="6" lg="6" xl="4" offset-xl="2"  class="pa-0 text-right">
     </v-col>
        </v-toolbar>
        <v-row>
            <v-col cols="8" sm="8"  class=" mt-5 text-left">
              <img  src="@/assets/dimensions.png" style="width:70%; height:70%" alt=""/>
            </v-col>
              <v-col cols="4" sm="4"  class=" mt-5 text-left">
                <div class="text-left">Longueur</div>
                  <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.longueur" hide-details dense ></v-text-field>
                <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.longueur}} </div>

                <div class=" mt-3 text-left">Largeur</div>
                  <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.largeur" hide-details dense ></v-text-field>
                <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.largeur}} </div>

                <div class="mt-3 text-left">Hauteur</div>
                  <v-text-field v-if="mode !== 'visualisation'"  single-line outlined v-model="projet.hauteur" hide-details dense ></v-text-field>
                <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.hauteur}} </div>
            </v-col>

        </v-row>



<v-container>
        <v-row class="table-fields">
          <v-col cols="12" sm="12" class="pb-0">
  <div class="text-left"><b>FACE 6 (Face de fixation du bloc) : Indiquez si trous débouchants sur face 3 ou trous taraudes:</b></div>
  <v-text-field v-if="mode !== 'visualisation'" label="" single-line outlined v-model="projet.face_6" hide-details dense ></v-text-field>
                <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{projet.face_6}} </div>

  </v-col>

       <v-col cols="12" sm="12" class="pb-0 ">
            <p class="text-left "><b> Indiquez sur les faces l'implantation des cartouches et des ports(nom et taille). Indiquez les éventuels réglages. <br>
    Si le bloc est à flasquer sur un distributeur, merci d'indiquer le modèle et la face d'attachement.</b></p>
      </v-col>
  </v-row>

  <v-row class="table-header">
    <v-col v-for="(column, index) in columns" :key="index" cols="2">
      <h2>{{ column }}</h2>
    </v-col>
  </v-row>
  <v-row v-for="(row, rowIndex) in projet.rows" :key="rowIndex" class="table-row">
    <v-col v-for="(column, index) in columns" :key="index" cols="2" class="table-cell" style="height: auto;">
      <v-text-field  v-if="mode !== 'visualisation'" v-model="row['face' + (index + 1)]" :label="'Face ' + (index + 1)" single-line outlined hide-details dense></v-text-field>
      <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold"  style="word-wrap: break-word;">{{row['face' + (index + 1)]}} </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col  v-if="mode !== 'visualisation'">
      <v-btn @click="removeRow(rowIndex)" >Supprimer la dernière ligne</v-btn>
      <v-btn @click="addRow" >Ajouter une ligne</v-btn>
    </v-col>
  </v-row>
</v-container>

      </div>
    <!-------------------------------- Bloc nouveau projet -------------------------------->

      
      <v-col cols="12" sm="10"  class="alignement_input ">
      <router-link :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}" style="text-decoration: none;">
        <v-btn v-if="mode !== 'visualisation'" @click="$store.state.currentTab = 1" class="btnBold mr-10"  color="black" plain>
          <div>
            Annuler
          </div>
        </v-btn>
      </router-link>
              <v-btn  v-if="mode !== 'visualisation'" class="btnBold"  color="#FF9900" dark rounded type="submit" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              ENREGISTRER
            </div>
      </v-btn>
      <router-link v-if="mode == 'visualisation'" :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}" style="text-decoration: none;">
        <v-btn @click="$store.state.currentTab = 1" class="btnBold"  color="#FF9900" dark rounded type="submit" >
          <div>
            RETOUR
          </div>
        </v-btn>
      </router-link>
      </v-col>

      </v-container>
      <v-col align-self="center" cols="2">
        <v-row justify="end">
          <v-dialog v-model="dialogConfirm" persistent max-width="615">
            <v-card class="card_border">
              <v-card-text class="pt-10  modal">
                <p class="text-modal">Un nouveau projet a été créé.</p>
                   <div class="">
                  <v-btn class="btn_choice " rounded text @click="redirectToFicheEntreprise()" >
                    ok
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </form>


  </div>
</template>
<script>
import ProjetBlocDescription from "@/components/Clients/ProjetBlocDescription";
import ProjetEtapes from "@/components/Clients/ProjetEtapes";
import AddArticles from "@/components/Clients/AddArticles";
import AddContacts from "@/components/Clients/AddContacts";
import AddRappels from "@/components/Clients/AddRappels";
// import AddDocuments from "@/components/Clients/AddDocuments"
import AddDocumentsNew from "@/components/Clients/AddDocumentsNew";
import api_doc from "@/api/document";

import { required, minLength, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  props: {
    projet: Object,
    utilisateurs: Array,
    mode: [String],
  },
  components: {
    ProjetBlocDescription,
    ProjetEtapes,
    AddContacts,
    AddArticles,
    AddRappels,
    AddDocumentsNew,
  },
  data() {
    return {
      checkIfPersonne: false,
      dialogConfirm: false,
      menu: false,
      dateFormatted: "",
      docs: [],
      tab: "Projet",
      projet_a_articles: [],
      zoomLevel: 1,
      columns: ['Face 1', 'Face 2', 'Face 3', 'Face 4', 'Face 5', 'Face 6'],
      isSelecting: false,
      documents: [],
      selectedFile: null,
      imageUrl: "https://cdn.vuetifyjs.com/images/parallax/material.jpg" 


    };
  },
  validations: {
    projet: {
      nom_projet: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(100),
      },
      date_projet: {
        required,
      },
    },
  },
  computed: {
    formatDate() {
      moment.locale();
      return this.projet.date_projet
        ? moment(this.projet.date_projet).format("L")
        : "";
    },
    createur() {
      var createur = this.utilisateurs.filter((obj) => {
        return obj.id_utilisateur === this.projet.id_utilisateur;
      });
      console.log(createur, 'createur');
      createur = createur[0] != undefined ? createur[0].nom : "non défini";
      return createur;
    },
  },
  methods: {
    closeRappel(rappel) {
      this.$emit("closeRappel", { id_rappel: rappel.id_rappel });
    },

    openTab(evt, cityName) {
      console.log("🚀 ~ openTab ~ evt:", evt)
      this.tab = cityName;
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
        tablinks[i].style.backgroundColor = '#55aacf';
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
      evt.currentTarget.style.backgroundColor = '#0b87ba';
    },

    submit() {
      // Validation du formulaire
      this.$v.$touch();
      this.$refs.ProjetBlocDescription.$v.$touch();
      this.$refs.ProjetBlocDescriptionCahier.$v.$touch();

      
      if (!this.$v.$invalid && !this.$refs.ProjetBlocDescription.$v.$invalid && this.projet.personnes.length > 0) {
        // Récupération des data des composant AddContacts, AddRappels
        this.projet.personnes = this.$refs.AddContacts.personnes;
        this.projet.rappels = this.$refs.AddRappels.rappels;

        console.log("object documents", this.$refs.AddDocumentsNew.documents);


      for (var i = 0; i < this.$refs.AddDocumentsNew.documents.mails_importants.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents.mails_importants[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents.mails_importants[i].files,
          });
        }
      }

            for (i = 0; i < this.$refs.AddDocumentsNew.documents.schemas_hydrauliques.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents.schemas_hydrauliques[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents.schemas_hydrauliques[i].files,
          });
        }
      }

            for (i = 0; i < this.$refs.AddDocumentsNew.documents.photos.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents.photos[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents.photos[i].files,
          });
        }
      }

            for (i = 0; i < this.$refs.AddDocumentsNew.documents.offres_fournisseur.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents.offres_fournisseur[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents.offres_fournisseur[i].files,
          });
        }
      }

            for (i = 0; i < this.$refs.AddDocumentsNew.documents._3d_2d.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents._3d_2d[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents._3d_2d[i].files,
          });
        }
      }

            for (i = 0; i < this.$refs.AddDocumentsNew.documents.autres.length; i++) {
        // console.log(this.$refs.AddDocumentsNew.documents[i])
         if (this.$refs.AddDocumentsNew.documents.autres[i].id_document === undefined) {
          this.docs.push({
            files: this.$refs.AddDocumentsNew.documents.autres[i].files,
          });
        }
      }



      console.log("this.docs", this.docs)

        if (this.projet.commentaire_ !== ""  ) {
            this.projet.commentaires.push(
             {commentaire : this.projet.commentaire_
                     }        )
        }
        console.log(this.projet.commentaires,this.projet)
        // Data du projet
        let obj = {
          id_projet: this.projet.id_projet,
          nom_projet: this.projet.nom_projet,
          date_projet: this.projet.date_projet,
          budget_potentiel: this.projet.budget_potentiel,
          description: this.projet.description,
          projet_obtenu: this.projet.projet_obtenu,
          commentaire_projet_obtenu: this.projet.commentaire_projet_obtenu,
          id_entreprise: this.$route.params.id_entreprise,
          id_utilisateur: this.projet.id_utilisateur,
          bloc_fore: this.projet.bloc_fore,
          application: this.projet.application,
          fonctionnement_bloc: this.projet.fonctionnement_bloc,
          debit_max: this.projet.debit_max,
          pression_max: this.projet.pression_max,
          protection: this.projet.protection,
          qte_annuelle: this.projet.qte_annuelle,
          qte_livraison: this.projet.qte_livraison,
          prix_objectif: this.projet.prix_objectif,
          etapes: this.projet.etapes,
          outils_specifiques: this.projet.outils_specifiques,
          cartouches_non_stockees: this.projet.cartouches_non_stockees,
          personnes: this.projet.personnes,
          rappels: this.projet.rappels,
          commentaire: this.projet.commentaire_,
          reglage_pression: this.projet.reglage_pression,
          frequence_test: this.projet.frequence_test,
          articles: this.projet.projet_a_articles,
          bloc_longueur: this.projet.longueur,
          bloc_largeur: this.projet.largeur,
          bloc_hauteur: this.projet.hauteur,
          face_6: this.projet.face_6,
          faces: this.projet.rows

        }
        console.log(obj,'objss')

        var config = {
            header : {
            'Content-Type' : 'multipart/form-data'
          }
        }

        this.$emit('emitProjet', { projet: obj, docs: this.docs, image: this.documents, config: config }) 



      }else {
        this.checkIfPersonne = true
      }

      // Scroll sur la première erreur de vuelidate
      if (this.$v.$invalid || this.$refs.ProjetBlocDescription.$v.$invalid) {
         this.$nextTick(() => {
          let domRect = document.querySelector('.errorMsg');
          domRect.scrollIntoView(); 
        });
      }
      
    },
    redirectToFicheEntreprise(){
      this.dialogConfirm = false
      this.$router.push({ path: "/client/"+this.$route.params.id_client });
    },

    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.documents = [];
      this.selectedFile = e.target.files[0];
      this.documents.push({
        files: this.selectedFile,
      });
      console.log("documents add_documents", this.documents, this.projet.id_projet);

      // Upload dans un temp si creation
      let document = this.selectedFile;
      if ( this.projet.id_projet == undefined){
            api_doc
        .uploadTemp(
          document
        )
        .then((res) => {
          console.log("res upload", res);
      //     this.imageUrl = "data:image/png;base64," + res.data;

       this.imageUrl = 'data:image/png;base64,' + res.data;
        });
      }else {
        console.log("up save")
                // Document
        let data = new FormData();
        data.append("mode", "projets");
        console.log("this documents", this.documents)
        // data.append('documents', this.documents);
          let file = this.documents[0].files;
          data.append("documents", file);
          data.append("directory", this.projet.id_projet);
          data.append("id_element", this.projet.id_projet);


        
          var config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };
            api_doc.uploadDocument(this.projet.id_projet, data, config).then((res) => {
            console.log("res upload", res);
            api_doc.getBase64File(this.projet.id_projet).then((file)=> {
              console.log("🚀 ~ api_doc.getBase64File ~ file:", file)
              this.imageUrl = 'data:image/png;base64,' + file.data;
            })
          });
        
      }

    },




    zoomIn() {
      const sensitivity = 0.1; // Sensitivity of zoom level change
      this.zoomLevel = Math.min(3, this.zoomLevel + sensitivity);
    },
    zoomOut() {
      const sensitivity = 0.1; // Sensitivity of zoom level change
      this.zoomLevel = Math.max(1, this.zoomLevel - sensitivity);
    },

    createNewRow() {
      const newRow = {};
      for (let i = 1; i <= this.columns.length; i++) {
        newRow['face' + i] = '';
      }
      return newRow;
    },
    addRow() {
      this.projet.rows.push(this.createNewRow());
    },
    removeRow() {
      this.projet.rows.pop();
    }
  },
  mounted() {
  },
  created(){
    this.projet.rows.push(this.createNewRow());

    if ( this.$route.params.id_projet !== undefined){
      console.log("🚀 ~ created ~ this.projet.id_projet:", this.$route.params.id_projet)
      
                      api_doc.getBase64File(this.$route.params.id_projet).then((file)=> {
              console.log("🚀 ~ api_doc.getBase64File ~ file:", file)
              this.imageUrl = 'data:image/png;base64,' + file.data;
            })
    }


  }
};
</script>
<style scoped>
.calendar-field {
  width: 20%;
}

.labelCheckbox {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  height: 24px;
}

.date-letter {
  font-size: 15px !important;
}

.text-modal {
  color: black;
  font-weight: normal;
  font-size: large;
}

.card_border.v-card.v-sheet.theme--light {
  border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

/* Style the tab */
.tab {
  overflow: hidden;
  background-color: white;
}

/* Style the buttons inside the tab */
.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}



/* Style the tab content */

.tab .tablinks {
  color: white;
  font-weight: bold;
  background-color: #55aacf;
}

/* Create an active/current tablink class */
.tab .tablinks .active {
  background-color: green !important;
}

/* .tab .tablinks .active {
  color: white;
  background-color: green;
} */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

.image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease;
}

  .table-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }

  .table-row {
    border-bottom: 1px solid #ddd;
  }

  .table-fields {
    border: 1px solid #ddd;
  }

    .table-cell {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .remove-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
