import axios from 'axios';

const RESSOURCE_NAME = 'projets';

export default {
    createProjet(data){
        console.log("🚀 ~ createProjet ~ data:", data)
        return axios.post(`${RESSOURCE_NAME}/create`,{
            nom_projet: data.nom_projet,
            date_projet: data.date_projet,
            description: data.description,
            budget_potentiel: data.budget_potentiel,
            bloc_fore: data.bloc_fore,
            application: data.application,
            fonctionnement_bloc: data.fonctionnement_bloc,
            debit_max: data.debit_max,
            pression_max: data.pression_max,
            protection: data.protection,
            qte_annuelle: data.qte_annuelle,
            qte_livraison: data.qte_livraison,
            prix_objectif: data.prix_objectif,
            etapes: data.etapes,
            outils_specifiques: data.outils_specifiques,
            cartouches_non_stockees: data.cartouches_non_stockees,
            id_entreprise: data.id_entreprise,
            id_utilisateur: data.id_utilisateur,
            personnes: data.personnes,
            commentaire: data.commentaire,
            rappels: data.rappels,
            projet_obtenu: data.projet_obtenu,
            commentaire_projet_obtenu: data.commentaire_projet_obtenu,
            reglage_pression: data.reglage_pression,
            frequence_test: data.frequence_test,
            articles: data.articles,
            bloc_longueur: data.bloc_longueur,
            bloc_largeur: data.bloc_largeur,
            bloc_hauteur: data.bloc_hauteur,
            face_6: data.face_6,
            faces: data.faces,

        })
    },
    updateProjet(data){
        return axios.post(`${RESSOURCE_NAME}/update/`+data.id_projet,{
            nom_projet: data.nom_projet,
            date_projet: data.date_projet,
            description: data.description,
            budget_potentiel: data.budget_potentiel,
            bloc_fore: data.bloc_fore,
            application: data.application,
            fonctionnement_bloc: data.fonctionnement_bloc,
            debit_max: data.debit_max,
            pression_max: data.pression_max,
            protection: data.protection,
            qte_annuelle: data.qte_annuelle,
            qte_livraison: data.qte_livraison,
            prix_objectif: data.prix_objectif,
            etapes: data.etapes,
            outils_specifiques: data.outils_specifiques,
            cartouches_non_stockees: data.cartouches_non_stockees,
            id_entreprise: data.id_entreprise,
            id_utilisateur: data.id_utilisateur,
            personnes: data.personnes,
            commentaire: data.commentaire,
            rappels: data.rappels,
            projet_obtenu: data.projet_obtenu,
            commentaire_projet_obtenu: data.commentaire_projet_obtenu,
            reglage_pression: data.reglage_pression,
            frequence_test: data.frequence_test,
            articles: data.articles,
            bloc_longueur: data.bloc_longueur,
            bloc_largeur: data.bloc_largeur,
            bloc_hauteur: data.bloc_hauteur,
            face_6: data.face_6,
            faces: data.faces,

        })
    },

    getProjetsBlocFore( size = process.env.VUE_APP_NB_DISPLAY, page = 0, order = 'date_projet' ,sort="ASC"){
        return axios.get(`${RESSOURCE_NAME}/bloc_fores?page=${page}&size=${size}&orderBy=${order}&sortBy=${sort}`);
            // '/ users / ActiveProjets ? page = 0 & size=1 & orderBy=nom_projet & sortBy=DESC'
    },

    getEtapesProjetBlocFore(id_projet = null) {
        if ( id_projet !== null) {
            return axios.get(`${RESSOURCE_NAME}/${id_projet}/etapes_bloc_fores`)
        }else {
            return axios.get(`${RESSOURCE_NAME}/etapes_bloc_fores`)
        }
    },
    getProjet(id_projet) {
        return axios.get(`${RESSOURCE_NAME}/${id_projet}`)

    },

    setProjetEncours(id_projet) {
        return axios.get(`${RESSOURCE_NAME}/encours/${id_projet}`)

    },

    getRappelsProjet(id_entreprise, id_projet){
        return axios.get(`${RESSOURCE_NAME}/${id_entreprise}/rappels/${id_projet}`)
    },
    
}